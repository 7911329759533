.projectsPanel{
    display: flex;
}
div .projectCard{
    margin-right: 2vh;
    margin-bottom: 2vh;
    height: auto;
    width: 400px;
    border-color: var(--contrast);
    background-color: var(--background);
    border-width: 2.5px;
}
div .projectTitle{
    font-family: var(--font-heading);
    font-size: 1.2rem;
    font-weight: var(--font-weight-heading);
    color: var(--contrast);
}
div .projectDesc{
    font-family: var(--font-text);
    color: var(--contrast2);
    font-size: 1.1rem ;
    margin-top: 1vh;
    margin-bottom: 25%;
    height: 8vh;
}
div .projectTechs{
    align-self: end;
    font-family: var(--font-text);
    color: var(--contrast2);
    font-size: 1.1rem;
    margin-top: 4vh;
    font-style: italic;
}
div .projectLinks{
    margin-top: 0.5vh;
}
.fa {
    margin-right: 10px;
}
div .MuiCardContent-root{
    padding: 20px;
}
@media only screen and (max-width: 1200px) {
    div .projectDesc{
        height: 150px;
        margin-bottom: auto;
    }
}
@media only screen and (max-width: 1024px) {
    .projectsPanel{
        display: block;
    }
    .projectCard{
        width: inherit;
        margin-bottom: 2vh;
        height: auto;
    }
}