.footer{
    padding: 1vh;
    max-width: 100%;
    color: var(--contrast2);
}
.symbol{
    margin-right: 10px;
}
@media only screen and (max-width: 768px) {
    .footer{
        width: 95%;
    }
}