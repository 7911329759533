.home{
    padding-top: 35vh;
    padding-bottom:40vh;
    margin-left: 0% !important;
    color: var(--contrast);
}
.heading{
    font-size: 4rem;
    font-family: var(--font-heading);
    font-weight: var(--font-weight-heading);
    max-width: 85%;
    text-transform: uppercase;
}
.subheading{
    font-size: 1.8rem;
    font-family: var(--font-heading);
    font-weight: var(--font-weight-heading);
    max-width: 75%;
}
.name{
    color: var(--contrast);
    font-weight: 600;
    text-shadow: 3px 3px var(--contrast2);
}
@media only screen and (max-width: 1024px) {
    .home{
        padding-top: 25vh;
    }
}
@media only screen and (max-width: 768px) {
    .heading{
        font-size: 3.5rem;
    }
    .subheading{
        font-size: 1.5rem;
        max-width: 85%;
    }
    .home{
        padding-top: 5vh;
        padding-bottom: 0vh;
    }
}