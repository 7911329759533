.about-photo{
    margin-left: 25px;
}
.about-photo img{
    max-width: 300px;
    height: auto;
    border: 3px solid var(--contrast);  
}
@media only screen and (max-width: 768px) {
    .about-photo{
        padding-top: 5%;
        margin-left: 0px;
    }
}